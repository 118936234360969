import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const Terminal = () => {
  return (
    <StaticImage
      height={300}
      placeholder={'blurred'}
      imgStyle={{ borderRadius: 6 }}
      src={'../../../static/image/terminal.jpg'}
      alt={'A terminal displaying a code snippet'}/>
  )
}

export default Terminal