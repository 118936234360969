import NavBar from './NavBar'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Hero from './Hero'
import Callouts from './Callouts'
import BringVisualSearch from './BringVisualSearch'
import ImageIntelligence from './ImageIntelligence'
import FooterCta from './FooterCta'
import Footer from './Footer'
import React from 'react'
import Features from './Features'
import Divider from '@material-ui/core/Divider'
import { useMediaQuery } from '@material-ui/core'
import useTheme from '@material-ui/core/styles/useTheme'
import CodeSnippet from './CodeSnippet'

const App = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const margin = isMobile ? 4 : 10

  return (
   <>
     <NavBar/>
     <Container>
       <Box
         mt={margin}>
         <Hero/>
       </Box>
       <Box
        mt={margin}>
         <Callouts/>
       </Box>
       <Box mt={margin}>
         <BringVisualSearch/>
       </Box>
       <Box mt={margin}>
         <ImageIntelligence/>
       </Box>
     </Container>
     <Box mt={margin}>
       <CodeSnippet/>
     </Box>
     <Container>
       <Box mt={margin}>
         <Features/>
       </Box>
       <Box mt={margin}>
         <Divider style={{'backgroundColor': 'lightgrey'}}/>
       </Box>
       <Box mt={margin}>
         <FooterCta/>
       </Box>
     </Container>
     <Box mt={margin}>
       <Footer/>
     </Box>
   </>
  )
}

export default App