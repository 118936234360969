import React, { useState } from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import useTheme from '@material-ui/core/styles/useTheme'
import logo from '../../static/image/pixelsearch_logo.png'
import doodle1 from '../../static/image/doodle1.png'
import doodle2 from '../../static/image/doodle2.png'


export default function Callout({ title, content, variant, doodle }) {
  const [elevation, setElevation] = useState(3)
  const theme = useTheme()
  return (
    <Paper
      style={{ height: '100%', cursor: 'default' }}
      onMouseEnter={() => setElevation(8)}
      onMouseLeave={() => setElevation(3)}
      elevation={elevation}>
      <Box p={3}>
        <Grid container justifyContent='center' alignItems='flex-end'>
          <Typography
            style={variant === 'accent' ? {'color': theme.palette.accent.main} : {}}
            color={variant === 'accent' ? 'initial' : variant} // Just to stop Material UI from complaining
            align='center'
            variant='h6'>
            {title}
          </Typography>
          <Box ml={2}>
            <img width={24} height={24} src={doodle === 'logo' ? logo : doodle === 'doodle1' ? doodle1 : doodle2 } alt=''/>
          </Box>
        </Grid>
        <Box mt={1}>
          <Typography
            align='center'>
            {content}
          </Typography>
        </Box>
      </Box>
    </Paper>
  )
}