import React from 'react'
import '../styles/global.scss'
import App from '../components/App'

export default function Home() {

  return (
    <>
      <App/>
    </>
  )
}
