import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const Iso1 = () => {
  return (
    <StaticImage
      height={300}
      placeholder={'blurred'}
      src={'../../../static/image/iso1.webp'}
      alt={'Scientists at work'}/>
  )
}

export default Iso1