import React from 'react'
import Feature from './Feature'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import UploadCloudSvg from '../../static/icons/upload-cloud.svg'
import CpuSvg from '../../static/icons/cpu.svg'
import ZapSvg from '../../static/icons/zap.svg'
import useTheme from '@material-ui/core/styles/useTheme'

const Features = () => {
  const theme = useTheme()
  return (
    <Box>
      <Grid container spacing={1} justifyContent={'center'}>
        {[{
          icon: <UploadCloudSvg/>,
          title: 'Store arbitrary data with your images',
          text: `PixelSearch enables you to set up to 64 custom fields on an image resource,
                 allowing you to create a powerful API without needing to enrich your search results.`,
          actionText: 'Sign up',
          actionColor: theme.palette.primary.main
        },
          {
            icon: <CpuSvg/>,
            title: 'Process images asynchronously',
            text: `Image processing runs in the background while you are building out your API. Once your images are ready, 
                   kick off your index build and let PixelSearch handle the rest.`,
            actionText: 'Sign up',
            actionColor: theme.palette.secondary.main
          },
          {
            icon: <ZapSvg/>,
            title: 'Lightning fast queries, every time',
            text: `Even when querying tens of thousands of images, PixelSearch provides accurate visually similar 
                   results with sub-second latency.`,
            actionText: 'Sign up',
            actionColor: theme.palette.accent.main
          },
        ].map((props, index) =>
          <Grid
            key={index}
            item xs={12} sm={6} md={4}
            data-sal={theme.scrollReveal.animation}
            data-sal-duration={theme.scrollReveal.duration}
            data-sal-delay={index * 100}>
            <Feature {...props}/>
          </Grid>)}
      </Grid>
    </Box>
  )
}

export default Features
