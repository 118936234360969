import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import useTheme from '@material-ui/core/styles/useTheme'
import { useMediaQuery } from '@material-ui/core'

const Feature = ({ icon, title, text, actionText, actionColor}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Box style={{textAlign: 'center'}} p={isMobile ? 3 : 6}>
      <Box>
        {icon}
      </Box>
      <Box mt={2}>
        <Typography
          style={{'color': actionColor, fontWeight: '450' }}
          variant='h5'>
          {title}
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography>
          {text}
        </Typography>
      </Box>
      {/*These action links are nice but we have nothing to do right now :( */}
      {/*<Box mt={2}>*/}
        {/*<Button*/}
        {/*  variant='text'*/}
        {/*  style={{'color': actionColor}}>*/}
        {/*  {actionText}*/}
        {/*  <ArrowSvg stroke={actionColor} style={{'marginLeft': '4px'}}/>*/}
        {/*</Button>*/}
      {/*</Box>*/}
    </Box>
  )
}

export default Feature