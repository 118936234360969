import React from 'react'
import Box from '@material-ui/core/Box'
import Terminal from './images/Terminal'
import Typography from '@material-ui/core/Typography'

const CodeSnippet = () => {
  return (
    <Box
      pt={6}
      pb={8}
      px={2}
      style={{ backgroundColor: '#F3F3F3', display: 'flex', justifyContent: 'center' }}>
      <Box style={{ textAlign: 'center' }}>
        <Box mb={5}>
          <Typography variant={'h3'}>
            It's just that simple
          </Typography>
          <Box mt={2} maxWidth={900}>
            <Typography>
              Once your index is ready, searching is a breeze. Query by a pre-indexed image for lighting fast results,
              or submit an unindexed image directly in your request to provide a truly dynamic experience to your users.
            </Typography>
          </Box>
        </Box>
        <Terminal/>
      </Box>
    </Box>
  )
}

export default CodeSnippet