import React from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

export default function Footer() {
  const footerSize = '100px'
  const classes = makeStyles({
    footer: {
      position: 'absolute',
      width: '100%',
      height: footerSize
    },
    footerImage: {
      position: 'absolute',
      height: footerSize,
      width: '100%',
      // backgroundImage: `url('./pixels1.jpg')`,
      backgroundSize: 'cover',
      opacity: '5%'
    },
    footerContent: {
      position: 'absolute',
      width: '100%',
      height: footerSize,
      textAlign: 'center'
    }
  })()
  return (
    <>
      <Box className={classes.footer}/>
      <Box className={classes.footerImage}/>
      <Box>
        <Grid container
              justifyContent='center'
              alignItems='center'
              className={classes.footerContent}>
          <Grid item>
            <Typography
              variant={'caption'}
              style={{ color: 'grey' }}>
              Copyright © 2021 PixelSearch. All rights reserved.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}