import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import RightArrow from '../../static/icons/arrow-right.svg'
import { makeStyles } from '@material-ui/styles'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const Signup = ({ responseColor }) => {
  const classes = makeStyles({
    textField: {
      fontSize: 'large',
      backgroundColor: 'white'
    },
    button: {
      textTransform: 'none',
      height: '100%',
      borderRadius: '8px',
      '&:hover': {
        background: '#B50055'
      }
    },
    buttonArrow: {
      marginLeft: '6px',
    },
  })()

  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const handleChange = event => {
    setEmail(event.target.value)
  }

  const handleSubmit = async () => {
    const res = await addToMailchimp(email)
    if (res.result === 'error') {
      setMessage('We had some issues adding your email. Please try again later.')
    } else {
      window.location.href = 'success'
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={9}>
          <TextField
            value={email}
            onChange={handleChange}
            className={classes.textField}
            variant='outlined'
            placeholder='Email'
            fullWidth/>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            onClick={handleSubmit}
            disableFocusRipple
            className={classes.button}
            variant='contained'
            color='primary'
            size='large'
            fullWidth>
            Submit
            <RightArrow stroke='white' style={{'marginLeft': '4px'}}/>
          </Button>
        </Grid>
      </Grid>
      <Box mt={1}>
        {message &&
        <Typography
          style={{'color': responseColor}}>
          <span>{message}</span>
        </Typography>
        }
      </Box>
    </>
  )
}

export default Signup