import React from 'react'
import Callout from './Callout'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { useTheme } from '@material-ui/core'

export default function Callouts () {
  const theme = useTheme()
  return (
    <Box>
      <Grid container spacing={3} alignItems='stretch' justifyContent='center'>
        <Grid item xs={12} sm={4}
              data-sal={theme.scrollReveal.animation}
              data-sal-duration={theme.scrollReveal.duration}>
          <Callout
            variant='primary'
            title='Get started for free'
            content="Start building with our free-tier plan to make sure PixelSearch is right for you. Don't worry about billing until you're ready to launch."
            doodle='logo'/>
        </Grid>
        <Grid item xs={12} sm={4}
          data-sal={theme.scrollReveal.animation}
          data-sal-duration={theme.scrollReveal.duration}
          data-sal-delay='100'>
          <Callout
            variant='secondary'
            title='Forget the infrastructure'
            content="With PixelSearch, your API is fully hosted on our servers. No need to provision hardware or manage complex configurations."
            doodle='doodle1'/>
        </Grid>
        <Grid item xs={12} sm={4}
          data-sal={theme.scrollReveal.animation}
          data-sal-duration={theme.scrollReveal.duration}
          data-sal-delay='200'>
          <Callout
            variant='accent'
            title='Query from anywhere'
            content='Generate read-only access keys to allow client applications to query your API directly. No back-end deployments required.'
            doodle='doodle2'/>
        </Grid>
      </Grid>
    </Box>
  )
}