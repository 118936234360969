import React from 'react'
import { Box, useMediaQuery, useTheme } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import bullet2 from '../../static/image/bullet_2.png'
import Typography from '@material-ui/core/Typography'
import './Content.css'
import Iso2 from './images/Iso2'

export default function ImageIntelligence() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  return (
    <Box pb={3}>
      <Grid container
            alignItems='center'
            justifyContent='space-between'
            spacing={4}>
        <Grid
          item
          xs={12} sm={6}
          style={{ order: isMobile ? 2 : 1 }}>
          <Grid container justifyContent='center'>
            <Iso2/>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12} sm={6}
          style={{ order: isMobile ? 1 : 2 }}>
          <Grid container justifyContent='flex-end'>
            <img
              draggable={false}
              height={36}
              width={36}
              src={bullet2}
              alt=''/>
          </Grid>
          <Box mt={3}>
            <Typography
              className={'content-header'}
              color='textSecondary'
              variant='h6'>
              Cost efficient
            </Typography>
          </Box>
          <Box mt={1}>
            <Typography variant='h4'>Image intelligence, without breaking the bank</Typography>
          </Box>
          <Box mt={4}>
            <Typography>
              Extracting valuable insights from images is traditionally an expensive and complex process.
              Building and deploying a custom algorithm requires an entire team of data scientists and engineers, and may take weeks or
              even months. With PixelSearch, a single developer can build your API by the end of the day.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}