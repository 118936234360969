import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Signup from './Signup'
import Similarity from '../../static/image/similarity.svg'
import * as styles from './Hero.module.css'
import './HeroSvg.css'
import { useMediaQuery } from '@material-ui/core'
import useTheme from '@material-ui/core/styles/useTheme'

export default function Hero() {

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <Box>
        <Box className={`${styles.background} ${styles.gradient}`}/>
        <Box className={`${styles.background} ${styles.image}`}/>
        <Grid
          className={styles.content}
          container
          alignItems='center'
          spacing={4}>
          <Grid item container justifyContent={'center'}>
            <Grid
              item
              xs={12}
              sm={8}>
              <Typography
                style={{ fontWeight: 500, fontFamily: 'Raleway' }}
                align={'center'}
                className={styles.whiteText}
                variant={isMobile ? 'h3' : 'h2'}>
                Visual search, simplified
              </Typography>
              <Box
                mt={1}>
                <Typography
                  align={'center'}
                  className={styles.whiteText}
                  variant={'h5'}>
                  Sign up for the closed beta and build your API for free
                </Typography>
              </Box> <Box mt={3}>
                <Typography
                  align={'center'}
                  className={styles.whiteText}>
                  You don't need a Ph.D. to bring visual search to your application.
                  Create an album, add your images, and PixelSearch will have them indexed in minutes.
                  Sign up below and start building today.
                </Typography>
              </Box>
              <Box mt={4}>
                <Signup responseColor='white'/>
              </Box>
            </Grid>
          </Grid>
          {/*<Grid container item xs={12} md={6}>*/}
          {/*  <Similarity style={{height: '100%'}}/>*/}
          {/*</Grid>*/}
        </Grid>
      </Box>
    </>
  )
}