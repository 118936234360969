import React from 'react'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Signup from './Signup'
import Box from '@material-ui/core/Box'

const FooterCta = () => {
  return (
    <>
      <Box mt={1}>
        <Typography variant='h4' align='center'>
          Sign up for our closed beta
        </Typography>
      </Box>
      <Box mt={1}>
        <Typography variant='subtitle1' align='center'>
          Visual search should be simple. Discuss your use-case with a real human and be among the first to get access.
        </Typography>
      </Box>
      <Box mt={3}>
        <Container maxWidth='sm'>
          <Signup/>
        </Container>
      </Box>
    </>
  )
}

export default FooterCta