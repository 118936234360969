import React from 'react'
import { Box } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import bullet1 from '../../static/image/bullet_1.png'
import './Content.css'
import Iso1 from './images/Iso1'

export default function BringVisualSearch () {
  return (
    <Box>
      <Grid container alignItems={'center'}>
        <Grid item xs={12} sm={6}>
          <Box>
            <img
              style={{ userSelect: 'none' }}
              draggable={false}
              height={36}
              width={36}
              src={bullet1}
              alt=''/>
          </Box>
          <Box mt={3}>
            <Typography
              className={'content-header'}
              color='textSecondary'
              variant='h6'>
              Ready for anything
            </Typography>
          </Box>
          <Box mt={1}>
            <Typography
              align={'left'}
              variant='h4'>
              Bring visual search to your application
            </Typography>
          </Box>
          <Box mt={4}>
            <Typography
              align={'left'}>
              Bring frictionless image search to your online store, or provide visually similar product recommendations to increase user engagement.
              Our image-intelligence algorithm is domain agnostic, so it's ready for whatever images you can throw at it, right out of the box.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container justifyContent='center'>
            <Iso1
              draggable={false}
              style={{ maxHeight: '80%', maxWidth: '80%', alignSelf: 'flex-start', userSelect: 'none' }}/>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}